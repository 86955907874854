<header>
  <div class="container-fluid night-shade">
    <div class="row">
      <div class="col-2 col-md-1 py-3">
        <img class="logo" src="assets/images/DrinkeyForeground.png">
      </div>
      <div class="col my-auto mx-auto">
        <H1 class="text-white">Drinkey</H1>
      </div>
    </div>
  </div>
</header>
