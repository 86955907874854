<div class="container">
  <div class="row text-center mb-5">
    <div class="col mt-5">
      <H3>Drinkey Android app.</H3>
    </div>
    <div class="col-12">
      <a
        href='https://play.google.com/store/apps/details?id=uk.co.snowballconsultancy.drinkey&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
        class="play-logo" alt='Get it on Google Play'
        src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
    </div>
  </div>

  <div class="row text-center mb-5">
    <div class="col-12 mb-2">
      <h5>Estimate maximum blood alcohol as you drink.</h5>
    </div>
    <div class="col-12">
      <div>
        <img src="assets/images/ScreenshotDrinkey.jpg" class="resize-image" alt="Real Time BAC Analysis">
      </div>
    </div>
  </div>

  <div class="row text-center mb-5">
    <div class="col-12 mb-2">
      <h5>Track units consumed on a week by week basis.</h5>
    </div>
    <div class="col-12">
      <div>
        <img src="assets/images/WeekByWeekChart.png" class="resize-image" alt="Week By Week">
      </div>
    </div>
  </div>

  <div class="row text-center">
    <div class="col-12">
      <p>Drinkey is a simple and easy to use alcohol tracker designed to help you understand how much alcohol you are
        consuming in both real
        time and over a period of time.</p>
      <a
        href='https://play.google.com/store/apps/details?id=uk.co.snowballconsultancy.drinkey&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
        class="play-logo" alt='Get it on Google Play'
        src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
    </div>
  </div>
</div>
