<footer>
  <div class="container">
    <div class="row py-1 py-md-3">
      <div class="col-6 text-center">
        <a routerLink="/privacy-policy">privacy policy</a>
      </div>
      <div class="col-6 text-center">
        <a routerLink="/terms-and-conditions">terms and conditions</a>
      </div>
    </div>
  </div>
</footer>
